<template>
  <div :id="`sub-task-${subTask.id}`">
    <tippy :to="`subtask-trigger-${subTask.id}`">
      <div class="flex space-x-2">
        <h4 class="font-semibold">Actioned By:</h4>
        <p>{{ subTask.actioned_by || 'NA' }}</p>
      </div>
      <div class="flex space-x-2">
        <h4 class="font-semibold">Completed By:</h4>
        <p>{{ subTask.completed_by || 'NA' }}</p>
      </div>
    </tippy>
    <div class="relative overflow-auto pb-1">
      <div class="grid rounded-lg h-12 grid-cols-12 border bg-white items-center ml-16">
        <!-- COL 1 -->
        <div class=" col-span-1 flex justify-center">
          <span :name="`subtask-trigger-${subTask.id}`" class="w-6 h-6 mr-2 flex cursor-pointer" v-html="subTaskIcon()" @click="updateSubTaskStatus(stageId, taskIndex, subTaskIndex)"></span>
        </div>
        <!-- COL 2 -->
        <div class="col-span-3">

          <div class="flex items-center">
            <h4 class="text-gray-700">{{ subTask.code }} {{ subTask.name }}</h4>

            <div v-for="user in subTask.managers"  class="rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-dark">
              <span class="font-semibold text-xs" v-html="makeInitials(user.name)"></span>
            </div>

            <div v-for="user in subTask.staff" class="rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-light">
              <span class="font-semibold text-xs" v-html="makeInitials(user.name)"></span>
            </div>
          </div>

        </div>
        <!-- COL 3 -->
        <div class="col-span-5">
          <span class="ml-4 flex items-center gap-1" :class="subTask.result ? 'text-gray-600' : 'text-gray-400'">
            <a href="#" @click.prevent="subTask.resultExpanded = !subTask.resultExpanded;">
              <svg v-if="!subTask.resultExpanded" class="w-5 h-5 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
            </a>
            {{ subTask.result ? (subTask.result.length > 33 ? subTask.result.slice(0, 36) + '...' : subTask.result) : 'No Result Recorded Yet' }}
          </span>
        </div>
        <!-- COL 4 -->
        <div class="col-span-2">
          <span v-if="subTask.completed_at" class="text-gray-400 text-sm">Completed {{ subTask.completed_at }}</span>

<!--      REMOVED AS REQUESTED JB 09.12.22
          <span v-else-if="subTask.status === 'in_progress'" class="">
          <span class="text-sm">Target Date </span>
            <input type="date" :value="subTask.target_date" class="text-sm" @change.prevent="updateSubTask(stageId, taskIndex, subTaskIndex, 'target_date', $event.target.value)">
          </span>
-->
          <span v-else class=""></span>
        </div>

        <!-- COL5 -->
        <div class="col-span-1 flex justify-center pr-4">
          <span class="text-right">
            <a v-if="subTask.learning_topic_id || subTask.learning_sub_topic_id" :href="`/client/${opportunityId}/${subTask.learning_sub_topic_id ? 'learning_sub_topics' : 'learning_topics'}/${subTask.learning_sub_topic_id ? subTask.learning_sub_topic_id : subTask.learning_topic_id}`" target="_blank" class="text-blue-500">
              Learn More
            </a>
          </span>
          <div class="flex items-center flex-shrink-0" v-if="subTask.description" @click="updateSubTask(stageId, taskIndex, subTaskIndex, 'showDescription', !subTask.showDescription)">
            <svg class="w-6 h-6 text-blue-500 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </div>
        </div>
      </div>
      <div v-if="subTask.resultExpanded" class="p-3 ml-16 bg-gray-200 text-gray-800 rounded-bl rounded-br">
        <textarea autofocus="true" rows="4" v-model="subTask.result" class="w-full py-2 px-4" placeholder="Sub-task result"></textarea>
        <div class="flex justify-end space-x-4">
          <svg class="w-5 h-5 text-brand-dark cursor-pointer" @click="updateSubTask(stageId, taskIndex, subTaskIndex, 'result', subTask.result)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
          <svg class="w-5 h-5 text-red-400" @click="updateSubTask(stageId, taskIndex, subTaskIndex, 'resultExpanded', false)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
        </div>
      </div>
      <div v-if="subTask.showDescription" class="p-3 ml-16 bg-gray-700 text-white rounded-bl rounded-br">
        {{ subTask.description }}
      </div>

      <!-- BOOKING ROWS-->
      <div v-if="subTask.bookings.length > 0">
        <div v-for="booking in subTask.bookings" :key="booking.id" class="grid grid-cols-12 border-b text-start bg-blue-50 ml-24">
          <!-- COL 1 BOOKING STATUS ICON-->
          <div class="p-2 border-l col-span-1 flex justify-center">
            <span
                class="w-6 h-6 mr-2 flex cursor-pointer"
                v-html="bookingIcon(booking.status)"
            >
            </span>
          </div>
          <!-- COL 2 TITLE-->
          <div class="p-2 col-span-8">
            <span :id="`process_sub_task_booking_${booking.id}_title`">{{ `${booking.title} Booking` }}</span>
          </div>
          <!-- COL 3 DATE-->
          <div class="p-2 col-span-2 text-gray-400 text-sm">
            <span> {{ `Schedule date: ${formatDate(booking.start)}` }} </span>
          </div>
          <!-- COL 4 LINK -->
          <div class="p-2 border-r col-span-1 flex justify-right justify-center text-sm">
            <a data-turbo-frame="modal" :href="`/staff/opportunities/${id}/bookings/${booking.id}/edit`" class="text-blue-500">
              View
            </a>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>
<script>
export default {
  props: ['subTask', 'subTaskIndex', 'updateSubTaskStatus', 'stageId', 'taskIndex', 'updateSubTask', 'opportunityId', 'makeInitials', 'id'],
  methods: {
    formatDate(dateString) {
      //var options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: 'numeric' };
      var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      var date = new Date(dateString);

      return date.toLocaleDateString('en-AU', options);
    },
    subTaskIcon: function() {
      switch (this.subTask.status) {
        case 'completed':
          return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
        case 'in_progress':
          return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
        case 'not_applicable':
          return '<svg class="bg-gray-200 text-gray-500 w-6 h-6 rounded-xl flex items-center justify-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
        default:
          return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
      }
    },
    bookingIcon(status) {
      switch (status) {
        case 'pending':
          return '<span class="bg-red-400 h-6 w-6 rounded-xl flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span> ';
        case 'confirmed':
          return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
        case 'complete':
          return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
        default:
          return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
      }
    },
  }
}
</script>
