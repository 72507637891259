<template>
  <div :id="`task-${task.id}`" class="outline outline-offset-2 outline-1">
    <div class="relative overflow-auto p-1">
      <div class="grid rounded-tl-lg rounded-tr-lg h-16 grid-cols-12 border bg-white items-center text-start ">
        <!-- COL 1 ICON-->
        <div class="rounded-tl-lg col-span-1 flex justify-center">
          <span :name="`task-trigger-${task.id}`"
                class="w-6 h-6 mr-2 flex cursor-pointer"
                v-html="taskIcon()"
                @click="updateTaskStatus(stageId, taskIndex)"
          >
          </span>
        </div>
        <!-- COL 2 NAME-->
        <div class="col-span-3">
          <div class="flex items-center">
            <h4 class="font-semibold text-gray-700 cursor-pointer" @click.prevent="task.toggled = !task.toggled">{{task.code}} {{ task.name }}</h4>

            <div v-for="user in task.managers"  class="rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-dark">
              <span class="font-semibold text-xs" v-html="makeInitials(user.name)"></span>
            </div>

            <div v-for="user in task.staff"  class="rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-light">
                  <span class="font-semibold text-xs" v-html="makeInitials(user.name)"></span>
            </div>

          </div>

        </div>
        <!-- COL 3 RESULT-->
        <div class="col-span-5">
          <span class="flex items-center ml-2" :class="task.result ? 'text-gray-600' : 'text-gray-400'">
            <a class="pr-2 "href="#" @click.prevent="toggleTask(stageId, taskIndex, 'resultExpanded', !task.resultExpanded)">
              <svg v-if="!task.resultExpanded" class="w-5 h-5 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
            </a>
            {{ task.result ? (task.result.length > 33 ? task.result.slice(0, 36) + '...' : task.result) : 'No Result Recorded Yet' }}
          </span>
        </div>
        <!-- COL 4 DATE-->
        <div class="col-span-2">
          <span v-if="task.completed_at" class="text-gray-400 text-sm ml-2">Completed {{ task.completed_at }}</span>

<!--      REMOVED AS REQUESTED JB 09.12.22
          <span v-else-if="task.status === 'in_progress' && task.sub_tasks.length === 0" class="ml-2">
              <span class="text-sm">Target Date </span>
              <input type="date" :value="task.target_date" class="text-sm" @change.prevent="updateTask(stageId, taskIndex, 'target_date', $event.target.value)">
            </span>
-->
          <span v-else class="text-sm"></span>
        </div>
        <!-- COL 5 DESCRIPTION-->
        <div class="col-span-1 flex justify-end pr-4">
          <span @click.prevent="task.toggled = !task.toggled" class="text-gray-500 text-sm cursor-pointer" :class="subTaskCountColour(task.sub_tasks)">{{ task.toggled ? '-' : '+' }} {{ task.sub_tasks.length }} sub-tasks</span>
          <div class="flex items-center ml-2" v-if="task.description" @click="task.showDescription = !task.showDescription">
            <svg class="w-6 h-6 text-blue-500 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </div>
        </div>
      </div>

      <div v-if="task.resultExpanded" class="bg-gray-200 text-gray-800 rounded-bl rounded-br">
        <textarea autofocus="true" rows="4" v-model="task.result" @change="updateTask(stageId, taskIndex, 'result', $event.target.value)" class="w-full py-2 px-4" placeholder="Task result"></textarea>
        <div class="flex justify-end space-x-4">
          <svg class="w-5 h-5 text-brand-dark cursor-pointer" @click="updateTask(stageId, taskIndex, 'result', task.result)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
          <svg class="w-5 h-5 text-red-400" @click="updateTask(stageId, taskIndex, 'resultExpanded', false)" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
        </div>
      </div>
      <div v-if="task.showDescription" class="p-3 bg-gray-700 text-white rounded-bl rounded-br">
        {{ task.description }}
      </div>

      <!-- BOOKING ROWS-->
      <div v-if="task.bookings.length > 0">
        <div v-for="booking in task.bookings" :key="booking.id" class="grid grid-cols-12 border-b text-start bg-blue-50 ml-8">
          <!-- COL 1 BOOKING STATUS ICON-->
          <div class="p-2 border-l col-span-1 flex justify-center">
            <span
                  class="w-6 h-6 mr-2 flex cursor-pointer"
                  v-html="bookingIcon(booking.status)"
            >
            </span>
          </div>
          <!-- COL 2 TITLE-->
          <div class="p-2 col-span-8">
            <span :id="`process_task_booking_${booking.id}_title`">{{ `${booking.title} Booking` }}</span>
          </div>
          <!-- COL 3 DATE-->
          <div class="p-2 col-span-2 text-gray-400 text-sm">
            <span> {{ `Schedule date: ${formatDate(booking.start)}` }} </span>
          </div>
          <!-- COL 4 LINK -->
          <div class="p-2 border-r col-span-1 flex justify-right justify-center text-sm">
            <a data-turbo-frame="modal" :href="`/staff/opportunities/${id}/bookings/${booking.id}/edit`" class="text-blue-500">
              View
            </a>
          </div>
        </div>
      </div>
    </div>

    <tippy :to="`task-trigger-${task.id}`" v-if="task.sub_tasks.length === 0">
      <div class="flex space-x-2">
        <h4 class="font-semibold">Actioned By:</h4>
        <p>{{ task.actioned_by || 'NA' }}</p>
      </div>
      <div class="flex space-x-2">
        <h4 class="font-semibold">Completed By:</h4>
        <p>{{ task.completed_by || 'NA' }}</p>
      </div>
    </tippy>

    <div v-if="task.toggled" class="">
      <sub-task
        v-for="(subTask, subTaskIndex) in task.sub_tasks"
        :key="`sub-task-${subTask.id}-${subTask.status}-${task.updated_at}`"
        :id="id"
        :sub-task="subTask"
        :sub-task-index="subTaskIndex"
        :update-sub-task-status="updateSubTaskStatus"
        :stage-id="stageId"
        :task-index="taskIndex"
        :update-sub-task="updateSubTask"
        :opportunity-id="id"
        :make-initials="makeInitials"
      ></sub-task>
    </div>
  </div>
</template>
<script>
import subTask from './sub_task';

export default {
  components: { subTask },
  props: [
    'task', 'taskIndex', 'updateTaskStatus', 'updateSubTaskStatus',
    'toggleTask', 'stageId', 'updateTask', 'updateSubTask', 'id','makeInitials'
  ],
  methods: {
    subTaskCountColour: function(subTasks) {
      let status;

      if (subTasks.length > 0) {
        if (subTasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
          status = 'text-blue-500';
        } else if (subTasks.some(st => st.status === 'in_progress' || st.status === 'completed')) {
          status = 'text-green-500';
        } else {
          status = 'text-gray-300';
        }
      } else {
        status = 'text-gray-500';
      }

      return status;
    },
    formatDate(dateString) {
      //var options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: 'numeric' };
      var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      var date = new Date(dateString);

      return date.toLocaleDateString('en-AU', options);
    },
    taskIcon: function() {
      let status = this.task.status;

      if (this.task.sub_tasks.length > 0) {
        // Get the status by the combined statuses of the sub-tasks
        if (this.task.sub_tasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
          status = 'completed';
        } else if (this.task.sub_tasks.some(st => st.status === 'in_progress' || st.status === 'completed')) {
          status = 'in_progress';
        } else {
          status = 'incomplete';
        }
      }

      switch (status) {
        case 'completed':
          return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
        case 'in_progress':
          return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
        case 'not_applicable':
          return '<svg class="bg-gray-200 text-gray-500 w-6 h-6 rounded-xl flex items-center justify-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
        default:
          return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
      }
    },
    bookingIcon(status) {
      switch (status) {
        case 'pending':
          return '<span class="bg-red-400 h-6 w-6 rounded-xl flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span> ';
        case 'confirmed':
          return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
        case 'complete':
          return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
        default:
          return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
      }
    },
  }
}
</script>
