<template>
  <div class="flex">
    <div class="flex-auto mr-6">
      <div class="p-4 rounded bg-gray-100 items-center mb-6 flex left space-x-2">
        <div v-if="workflow_stage != 'construction'" class="flex space-x-4 items-center flex-wrap">
          <div v-for="result in pinnedResults" :key="result.key" class="text-center">
            <label class="font-semibold text-sm text-gray-500">{{ result.name }}</label>
            <p class="text-gray-800">{{ result.result ? result.result : 'Not Set' }}</p>
          </div>
        </div>

        <div class="flex flex-col">
          <label class="font-semibold text-gray-800">Show Tasks For</label>
          <select v-model="workflow_stage_id" class="form-select">
            <option v-for="stage in workflow_stages" :value="stage.id" :key="stage.id">
              {{ stage.name }}
            </option>
          </select>
        </div>

        <div class="flex flex-col">
          <label class="font-semibold text-gray-800">Show Assigned to</label>
          <select v-model="assigned_user_ids_filter" @change="reloadStages()"
                  class="rounded-lg p-2"
                  style="min-width: 200px; --ss-main-height: 2.75rem; --ss-primary-color: rgba(45, 104, 142,1);"
                  data-controller="slimselect"
                    data-slimselect-options-value="{&quot;settings&quot;:{&quot;closeOnSelect&quot;:true,&quot;placeholderText&quot;:&quot;All&quot;}}"
                  multiple>
            <option v-for="user in users" :value="user.id" :key="user.id">
              {{ user.name }}
            </option>
          </select>
        </div>


      </div>
      <div class="bg-gray-100 rounded mt-4" v-for="stage in shownStages" :key="stage.id">
        <div @click.prevent="stage.toggled = !stage.toggled" class="rounded cursor-pointer text-gray-800 flex items-center justify-between px-4 py-2" :class="stageStyle(stage)">

          <div class="flex">
            <h3 class="font-semibold text-xl">{{stage.code }} {{stage.name}}</h3>


            <div v-for="user in stage.managers" class="rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-dark">
                <span class="font-semibold text-xs">
                   {{makeInitals(user.name)}}
                </span>
            </div>

            <div v-for="user in stage.staff" class="rounded-full flex items-center flex-shrink-0 justify-center h-5 w-5 p-3 ml-2 mt-1 text-white bg-brand-light">
                <span class="font-semibold text-xs">
                   {{makeInitals(user.name)}}
                </span>
            </div>

          </div>

          <span v-html="stageIcon(stage)"></span>

        </div>
          <div v-if="stage.toggled" class="p-4">
            <task
              v-for="(task, taskIndex) in stage.tasks"
              :key="`task-${task.id}-${task.status}-${task.resultExpanded}`"
              :id="id"
              :task="task"
              :stage-id="stage.id"
              :task-index="taskIndex"
              :toggle-task="toggleTask"
              :update-task-status="updateTaskStatus"
              :update-sub-task-status="updateSubTaskStatus"
              :update-task="updateTask"
              :update-sub-task="updateSubTask"
              :make-initials="makeInitals"
              >
            </task>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import VueTailwindDatepicker from '@coding-wisely/vue-tailwind-datepicker'
  import task from './task';
  import Vue from 'vue';
  import _ from 'lodash';

  export default {
    components: { task, VueTailwindDatepicker },
    data() {
      return {
        original_funnel_stages: null,
        assigned_user_ids_filter: [],
        id: null,
        funnel_stages: [],
        active_stage: null,
        sidebar: 'notes',
        lead_source_id: null,
        lead_status_id: null,
        user_id: null,
        opp_type: '',
        lead_sources: [],
        lead_statuses: [],
        confidence_scores: [],
        confidence_score_id: '',
        users: [],
        pageLoaded: false,
        workflow_stage: '',
        workflow_stage_id: '',
        workflow_stages: [],
        is_admin: false,
        tasks_saving: [],
        sub_tasks_saving: [] 
      }
    },
    mounted() {
      this.id = document.getElementById('opportunity-id').dataset.id;
      this.loadOpportunity();
    },
    watch: {
      lead_source_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },
/*      user_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },*/
      lead_status_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },
      opp_type: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },
      confidence_score_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      }
    },
    computed: {
      shownStages() {
        let allowedStages = [];

        Object.keys(this.funnel_stages).forEach(key => {
          if (this.funnel_stages[key].workflow_stage_id === this.workflow_stage_id) {
            allowedStages = [
              ...allowedStages,
              this.funnel_stages[key]
            ]

          }
        });

        let filteredStages = []

        this.assigned_user_ids_filter = this.assigned_user_ids_filter.map(Number);

        if (this.assigned_user_ids_filter.length > 0 && this.assigned_user_ids_filter[0] !== null) {
          Object.keys(allowedStages).forEach(stage_key => {

            if (allowedStages[stage_key].staff.some(s => this.assigned_user_ids_filter.includes(s.id)) || allowedStages[stage_key].managers.some(m => this.assigned_user_ids_filter.includes(m.id))) {
              // if stage is assigned to the selected user then add the entire stage (all tasks & sub-tasks)commissions
              filteredStages = [...filteredStages, allowedStages[stage_key]];
            } else {

              let allowedTasks = [];
              Object.keys(allowedStages[stage_key].tasks).forEach(task_key => {
                if (allowedStages[stage_key].tasks[task_key].staff.some(s => this.assigned_user_ids_filter.includes(s.id)) || allowedStages[stage_key].tasks[task_key].managers.some(m => this.assigned_user_ids_filter.includes(m.id))) {
                  allowedTasks = [...allowedTasks, allowedStages[stage_key].tasks[task_key]];
                } else {

                  let allowedSubTasks = [];
                  Object.keys(allowedStages[stage_key].tasks[task_key].sub_tasks).forEach(sub_task_key => {
                    if (allowedStages[stage_key].tasks[task_key].sub_tasks[sub_task_key].staff.some(s => this.assigned_user_ids_filter.includes(s.id)) || allowedStages[stage_key].tasks[task_key].sub_tasks[sub_task_key].managers.some(m => this.assigned_user_ids_filter.includes(m.id))) {
                      allowedSubTasks = [...allowedSubTasks, allowedStages[stage_key].tasks[task_key].sub_tasks[sub_task_key]];
                    }
                  });

                  if (allowedSubTasks.length > 0 ) {
                    allowedStages[stage_key].tasks[task_key].sub_tasks = allowedSubTasks;
                    allowedTasks = [...allowedTasks, allowedStages[stage_key].tasks[task_key]]
                  }

                }
              });

              if (allowedTasks.length > 0 ) {
                // add the entire task (incl all sub-tasks)
                allowedStages[stage_key].tasks = allowedTasks;
                filteredStages = [...filteredStages, allowedStages[stage_key]]
              }

            }
          });

          allowedStages = filteredStages;
        }

        return allowedStages;
      },
      pinnedResults() {
        let results = [];

        Object.keys(this.funnel_stages).forEach(fs => {
          const stages = this.funnel_stages;

          Object.keys(stages[fs].tasks).forEach(task => {
            stages[fs].tasks[task].show_result_in_header && results.push({
              type: 'task',
              name: stages[fs].tasks[task].name,
              result: stages[fs].tasks[task].result,
              key: `task-${task}`
            });

            Object.keys(stages[fs].tasks[task].sub_tasks).forEach(st => {
              stages[fs].tasks[task].sub_tasks[st].show_result_in_header && results.push({
              type: 'sub_task',
              name: stages[fs].tasks[task].sub_tasks[st].name,
              result: stages[fs].tasks[task].sub_tasks[st].result,
              key: `sub_task-${stages[fs].tasks[task].sub_tasks[st].id}`
              });
            });
          });
        });

        return results;
      }
    },
    methods: {
      reloadStages() {
        if (this.original_funnel_stages != null) {
          // create a deep clone of the original stages data
          this.funnel_stages = JSON.parse(JSON.stringify(this.original_funnel_stages));
        }
      },
      makeInitals(name) {
        return name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
      },
      stageStyle(stage) {
        switch(stage.status) {
          case 'completed':
            return 'bg-brand-light';
          case 'in_progress':
            return 'bg-green-100';
          default:
            return 'bg-gray-200';
        }
      },
      stageIcon(stage) {
        switch(stage.status) {
          case 'complete':
            return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
          case 'in_progress':
            return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
          default:
            return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
        }
      },
      updatedStatus(task) {
        switch (task.status) {
          case 'not_applicable':
            return 'incomplete';
          case 'in_progress':
            return 'completed';
          case 'completed':
            return 'not_applicable';
          default:
            return 'in_progress';
        }
      },
      getStageIndex(stageId) {
        return this.funnel_stages.findIndex(fs => fs.id === stageId);
      },
      toggleTask(stageId, taskIndex, attr, value) {
        let stages = [...this.funnel_stages];
        const stageIndex = this.getStageIndex(stageId);

        stages[stageIndex].tasks[taskIndex][attr] = value;

        if (attr === 'result') {
          stages[stageIndex].tasks[taskIndex].resultExpanded = false;
        }

        this.funnel_stages = stages;
      },
      updateTaskStatus(stageId, taskIndex) {
        const stageIndex = this.getStageIndex(stageId);
        let task = this.funnel_stages[stageIndex].tasks[taskIndex];

        // If the task itself can be updated:
        if (task.sub_tasks.length === 0) {
          // Check that the task isn't already in the process of being saved
          if (this.tasks_saving.findIndex(t => _.isEqual(t, [stageIndex, taskIndex])) === -1) {
            this.tasks_saving = [...this.tasks_saving, [stageIndex, taskIndex]];
            this.$set(
              this.funnel_stages[stageIndex].tasks[taskIndex],
              'status',
              this.updatedStatus(this.funnel_stages[stageIndex].tasks[taskIndex])
            )

            this.saveTask(this.funnel_stages[stageIndex].tasks[taskIndex], stageIndex, taskIndex);
          }
        }

        if (this.is_admin) {
          if (task.sub_tasks.every(st => st.status === 'incomplete')) {
            this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks.forEach((st, subTaskIndex) => {
              // Ensure these sub-tasks aren't already being processed by the server
              if (this.sub_tasks_saving.findIndex(sts => _.isEqual(sts, [stageIndex, taskIndex, subTaskIndex])) === -1) {
                Vue.set(this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'status', 'not_applicable');
                this.saveSubTask(st, stageIndex, taskIndex, subTaskIndex);
              }
            });
          } else if (task.sub_tasks.every(st => st.status === 'not_applicable')) {
            this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks.forEach((st, subTaskIndex) => {
              // Ensure these sub-tasks aren't already being processed by the server
              if (this.sub_tasks_saving.findIndex(sts => _.isEqual(sts, [stageIndex, taskIndex, subTaskIndex])) === -1) {
                Vue.set(this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'status', 'incomplete');
                this.saveSubTask(st, stageIndex, taskIndex, subTaskIndex);
              }
            });
          }
        }
      },
      updateSubTaskStatus(stageId, taskIndex, subTaskIndex) {
        const stageIndex = this.getStageIndex(stageId);
        const subTask = this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex];
        const status = this.updatedStatus(subTask);

        // Check that the sub-task isn't already in the process of being saved
        if (this.sub_tasks_saving.findIndex(st => _.isEqual(st, [stageIndex, taskIndex, subTaskIndex])) === -1) {
          this.sub_tasks_saving = [...this.sub_tasks_saving, [stageIndex, taskIndex, subTaskIndex]];
          this.$set(subTask, 'status', status);
          this.saveSubTask(this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], stageIndex, taskIndex, subTaskIndex);
        }
      },
      updateTask(stageId, taskIndex, attr, value) {
        let stages = [...this.funnel_stages];
        const stageIndex = this.getStageIndex(stageId);

        stages[stageIndex].tasks[taskIndex][attr] = value;

        if (attr === 'result') {
          stages[stageIndex].tasks[taskIndex].resultExpanded = false;
        }

        this.funnel_stages = stages;

        this.saveTask(stages[stageIndex].tasks[taskIndex], stageIndex, taskIndex);
      },
      updateSubTask(stageId, taskIndex, subTaskIndex, attr, value) {
        let stages = [...this.funnel_stages];
        const stageIndex = this.getStageIndex(stageId);

        Vue.set(stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], attr, value);

        if (attr === 'result') {
          Vue.set(stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'resultExpanded', false);
        }

        this.saveSubTask(stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], stageIndex, taskIndex, subTaskIndex);
      },
      loadOpportunity() {
        const url = `/staff/opportunities/${this.id}.json`;

        axios.get(url)
          .then(res => {
            let funnel_stages = [...res.data.funnel_stages];

            res.data.opp_funnel_tasks.forEach(oft => {
              funnel_stages.forEach((fs, stageIndex) => {
                const taskIndex = fs.tasks.findIndex(t => t.id === oft.funnel_task_id);

                if (taskIndex !== -1) {
                  funnel_stages[stageIndex].tasks[taskIndex] = {
                    ...oft,
                    ...funnel_stages[stageIndex].tasks[taskIndex],
                    opp_funnel_task_id: oft.id
                  }
                }
              });
            });

            res.data.opp_funnel_sub_tasks.forEach(oft => {
              funnel_stages.forEach((fs, stageIndex)=> {
                fs.tasks.forEach((t, taskIndex) => {
                  const subTaskIndex = t.sub_tasks.findIndex(st => {
                    return st.id === oft.funnel_sub_task_id
                  });

                  if (subTaskIndex !== -1) {
                    funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex] = {
                      ...funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex],
                      ...oft,
                      opp_funnel_sub_task_id: oft.id
                    }

                    
                  }
                });
              });
            });

            funnel_stages = funnel_stages.map(stage => {
              return {
                ...stage,
                toggled: false, //res.data.active_stage === stage.id
                showDescription: false,
                tasks: stage.tasks.map(task => {
                  let taskStatus = 'incomplete';

                  if (task.sub_tasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
                    taskStatus = 'completed';
                  } else if (task.sub_tasks.some(st => st.status === 'in_progress' || st.status === 'completed')) {
                    taskStatus = 'in_progress';
                  }

                  return {
                    ...task,
                    status: task.status ? task.status : 'incomplete',
                    toggled: false,
                    showDescription: false,
                    resultExpanded: false,
                    completedStatus: task.sub_tasks.length > 0 ? taskStatus : task.status,
                    sub_tasks: task.sub_tasks.map(subTask => {
                      return {
                        ...subTask,
                        status: subTask.status ? subTask.status : 'incomplete',
                        resultExpanded: false
                      }
                    })
                  }
                })
              }
            });

            funnel_stages = funnel_stages.map(stage => {
              let status = 'incomplete';

              if (stage.tasks.every(t => t.completedStatus === 'completed')) {
                status = 'completed';
              } else if (stage.tasks.some(t => t.completedStatus === 'in_progress' || t.completedStatus === 'completed')) {
                status = 'in_progress';
              }

              return { ...stage, status };
            });
            this.original_funnel_stages = funnel_stages;
            this.funnel_stages = funnel_stages;
            this.is_admin = res.data.is_admin;
            this.workflow_stage = res.data.workflow_stage;
            this.workflow_stage_id = res.data.workflow_stage_id;
            this.lead_source_id = res.data.lead_source_id;
            this.lead_status_id = res.data.lead_status_id;
            this.confidence_score_id = res.data.confidence_score_id;
            this.user_id = res.data.user_id;
            this.users = res.data.users;
            this.opp_type = res.data.opp_type;
            this.lead_statuses = res.data.lead_statuses;
            this.lead_sources = res.data.lead_sources;
            this.confidence_scores = res.data.confidence_scores;
            this.workflow_stages = res.data.workflow_stages;

            window.setTimeout(() => {
              this.pageLoaded = true;
              const params = new URLSearchParams(window.location.search);
              const taskId = params.get('task_id');
              const subTaskId = params.get('sub_task_id');

              if (taskId) this.scrollToTask(taskId);
              if (subTaskId) this.scrollToSubTask(subTaskId);
            }, 500);
          });
      },
      scrollToTask(id) {
        let taskIndex = -1;
        const activeStageIndex = this.funnel_stages.findIndex(fs => {
          taskIndex = fs.tasks.findIndex(t => t.id == id);
        
          if (taskIndex > -1) return true;
        });

        if (taskIndex && activeStageIndex) {
          Vue.set(this.funnel_stages[activeStageIndex], 'toggled', true);
          Vue.set(this.funnel_stages[activeStageIndex].tasks[taskIndex], 'toggled', true);
        }

        window.setTimeout(() => {
          document.getElementById(`task-${id}`).scrollIntoView();
        }, 300);
      },
      scrollToSubTask(id) {
        let taskIndex = -1;
        const activeStageIndex = this.funnel_stages.findIndex(fs => {
          taskIndex = fs.tasks.findIndex(t => t.sub_tasks.findIndex(st => st.id == id) > -1);
        
          if (taskIndex > -1) return true;
        });

        if (taskIndex && activeStageIndex) {
          Vue.set(this.funnel_stages[activeStageIndex], 'toggled', true);
          Vue.set(this.funnel_stages[activeStageIndex].tasks[taskIndex], 'toggled', true);
        }

        window.setTimeout(() => {
          document.getElementById(`sub-task-${id}`).scrollIntoView();
        }, 300);
      },
      saveWithThrottle: function() { this.saveOpportunity() },
      saveTask: function(task, stageIndex, taskIndex) {
        let url = `/staff/opp_funnel_tasks.json`;
        let method = 'post';
        let data = {
          opp_funnel_task: {
            status: task.status,
            funnel_task_id: task.id,
            opportunity_id: this.id,
            result: task.result
          }
        };

        if (task.opp_funnel_task_id) {
          url = `/staff/opp_funnel_tasks/${task.opp_funnel_task_id}.json`;
          method = 'patch';
          data = { opp_funnel_task: task };
        }

        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };

        axios({ method, url, data, headers }).then(({ data }) => {
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex], 'completed_by', data.completed_by);
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex], 'actioned_by', data.actioned_by);
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex], 'completed_at', data.completed_at);
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex], 'opp_funnel_task_id', data.id);

          let stageStatus = 'incomplete';

          if (this.funnel_stages[stageIndex].tasks.every(t => t.status === 'completed' || t.status === 'not_applicable')) {
            stageStatus = 'completed';
          } else if (this.funnel_stages[stageIndex].tasks.some(t => t.status === 'in_progress' || t.sub_tasks.some(st => st.status === 'in_progress'))) {
            stageStatus = 'in_progress';
          }

          this.$set(this.funnel_stages[stageIndex], 'status', stageStatus);

          const savingIndex = this.tasks_saving.findIndex(ts => _.isEqual(ts, [stageIndex, taskIndex]));

          if (savingIndex > -1) {
            this.task_saving = [...this.tasks_saving.splice(savingIndex, 1)];
          }
        });
      },
      saveSubTask: function(sub_task, stageIndex, taskIndex, subTaskIndex) {
        let url = `/staff/opp_funnel_sub_tasks.json`;
        let method = 'post';
        let data = {
          opp_funnel_sub_task: {
            status: sub_task.status,
            opportunity_id: this.id,
            funnel_sub_task_id: sub_task.id,
            result: sub_task.result
          }
        };

        if (sub_task.opp_funnel_sub_task_id) {
          url = `/staff/opp_funnel_sub_tasks/${sub_task.opp_funnel_sub_task_id}.json`;
          method = 'patch';
          data = { opp_funnel_sub_task: sub_task };
        }

        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };

        axios({ url, method, data, headers }).then(({ data }) => {
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'completed_by', data.completed_by);
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'actioned_by', data.actioned_by);
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'completed_at', data.completed_at);
          this.$set(this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'opp_funnel_sub_task_id', data.opp_funnel_sub_task_id);

          if (this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
            this.$set(this.funnel_stages[stageIndex].tasks[taskIndex], 'status', 'completed');
          } else if (this.funnel_stages[stageIndex].tasks[taskIndex].sub_tasks.some(st => st.status === 'in_progress')) {
            this.$set(this.funnel_stages[stageIndex].tasks[taskIndex], 'status', 'in_progress');
          } else {
            this.$set(this.funnel_stages[stageIndex].tasks[taskIndex], 'status', 'incomplete');
          }

          let stageStatus = 'incomplete';
          if (this.funnel_stages[stageIndex].tasks.every(t => t.status === 'completed' || t.status === 'not_applicable')) {
            stageStatus = 'completed';
          } else if (this.funnel_stages[stageIndex].tasks.some(t => t.status === 'in_progress')) {
            stageStatus = 'in_progress';
          }
          this.$set(this.funnel_stages[stageIndex], 'status', stageStatus);

          const savingIndex = this.sub_tasks_saving.findIndex(sts => _.isEqual(sts, [stageIndex, taskIndex, subTaskIndex]));

          if (savingIndex > -1) {
            this.sub_task_saving = [...this.sub_tasks_saving.splice(savingIndex, 1)];
          }

        });
      },
      saveOpportunity() {
        const url = `/staff/opportunities/${this.id}.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const activeStage = this.funnel_stages.find(stage => {
          return stage.tasks.some(t => t.status === 'in_progress' || t.status === 'incomplete');
        });
        const activeStageId = activeStage ? activeStage.id : null;
        const data = {
          opportunity: {
            lead_source_id: this.lead_source_id,
            lead_status_id: this.lead_status_id,
            confidence_score_id: this.confidence_score_id,
            opp_type: this.opp_type,
            //user_id: this.user_id,
            active_stage: activeStageId
          }
        };

        axios.patch(url, data, { headers });
      }
    }
  }
</script>