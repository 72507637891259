<template>
  <div v-on-clickaway="clickAway" class="relative ml-4">
    <input type="text" @click="clickIn" @input="triggerSearch" :value="search" name="nav-search" id="nav-search" class="border border-gray-200 rounded leading-5 px-4 py-2" placeholder="Search for clients or projects" />  
    <div v-if="showResults" class="bg-white shadow-lg absolute rounded-b-lg b-0 l-0 w-full p-4 divide-y divide-gray-100">
      <a :href="`/staff/opportunities/${result.id}`" v-for="result in results" :key="result.id" class="text-sm block py-1 my-1 px-2 text-brand-dark hover:bg-brand-dark hover:text-white rounded-lg">
        <h4 class="font-semibold">{{ result.address || 'No Address' }} - {{ result.name }}</h4>
        <p class="text-xs text-gray-500">{{ result.workflow_stage }}</p>
      </a>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  data() {
    return {
      search: '',
      results: [],
      showResults: false
    }
  },
  methods: {
    triggerSearch(e) {
      this.search = e.target.value;

      if (this.search.length < 3) {
        this.results = [];
        this.showResults = false;
        return;
      }

      const url = `/staff/search.json?search=${this.search}`;
        
      axios.get(url).then(res => {
        this.results = res.data;
        this.showResults = true;
      });
    },
    clickIn() {
      if (this.results.length > 0) {
        this.showResults = true;
      }
    },
    clickAway(e) {
      this.showResults = false;
    }
  }
}
</script>