<template>
  <div class="flex">
    <div class="flex-auto">
      <div class="p-4 rounded bg-gray-100 items-center mb-6 flex justify-between">
        <div v-if="workflow_stage != 'construction'" class="flex space-x-4 items-center flex-wrap">
          <div v-for="result in pinnedResults" :key="result.key" class="text-center">
            <label class="font-semibold text-sm text-gray-500">{{ result.name }}</label>
            <p class="text-gray-800">{{ result.result ? result.result : 'Not Set' }}</p>
          </div>
        </div>
        <div class="flex flex-col">
          <label class="font-semibold text-gray-800">Show Tasks For</label>
          <select v-model="workflow_stage_id" class="form-select">
            <option v-for="stage in workflow_stages" :value="stage.id" :key="stage.id">
              {{ stage.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="bg-gray-100 rounded mt-4" v-for="stage in shownStages" :key="stage.id">
        <div @click.prevent="stage.toggled = !stage.toggled" class="rounded cursor-pointer text-gray-800 flex items-center justify-between px-4 py-2 shadow-xl hover:shadow transition-shadow duration-100 ease-in-out hover:bg-blue-100">
          <h3 class="font-semibold text-xl">{{ stage.name }}</h3>
          <span v-html="stageIcon(stage)"></span>
        </div>
          <div v-if="stage.toggled" class="p-4">
            <task
              v-for="(task, taskIndex) in stage.tasks"
              :key="`task-${task.id}-${task.status}-${task.resultExpanded}`"
              :task="task"
              :documents="task.client_documents"
              :stage-id="stage.id"
              :task-index="taskIndex"
              :toggle-task="toggleTask"
              :update-task-status="updateTaskStatus"
              :update-sub-task-status="updateSubTaskStatus"
              :update-task="updateTask"
              :update-sub-task="updateSubTask"
              :add-document="addDocumentToTask"
              :update-documents="updateDocuments"
              :opportunity-id="id"
              >
            </task>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import axios from 'axios';
  var debounce = require('lodash/debounce');
  import VueTailwindDatepicker from '@coding-wisely/vue-tailwind-datepicker'
  import task from './task';

  export default {
    components: { task, VueTailwindDatepicker },
    data() {
      return {
        id: null,
        client_stages: [],
        active_stage: null,
        sidebar: 'notes',
        lead_source_id: null,
        lead_status_id: null,
        user_id: null,
        opp_type: '',
        lead_sources: [],
        lead_statuses: [],
        confidence_scores: [],
        confidence_score_id: '',
        users: [],
        pageLoaded: false,
        workflow_stage: '',
        workflow_stage_id: '',
        workflow_stages: [],
      }
    },
    mounted() {
      this.id = document.getElementById('opportunity-id').dataset.id;
      this.loadOpportunity();
    },
    watch: {
      lead_source_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },
      user_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },
      lead_status_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },
      opp_type: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      },
      confidence_score_id: function() {
        if (!this.pageLoaded) return;

        this.saveWithThrottle();
      }
    },
    computed: {
      shownStages() {
        let allowedStages = [];

        Object.keys(this.client_stages).forEach(index => {
          if (this.client_stages[index].workflow_stage_id === this.workflow_stage_id || this.client_stages[index].workflow_stage_id === null) {
            allowedStages = [
              ...allowedStages,
              this.client_stages[index]
            ]
          }
        });

        return allowedStages;
      },
      pinnedResults() {
        let results = [];

        Object.keys(this.client_stages).forEach(fs => {
          const stages = this.client_stages;

          Object.keys(stages[fs].tasks).forEach(task => {
            stages[fs].tasks[task].show_result_in_header && results.push({
              type: 'task',
              name: stages[fs].tasks[task].name,
              result: stages[fs].tasks[task].result,
              key: `task-${task}`
            });

            Object.keys(stages[fs].tasks[task].sub_tasks).forEach(st => {
              stages[fs].tasks[task].sub_tasks[st].show_result_in_header && results.push({
              type: 'sub_task',
              name: stages[fs].tasks[task].sub_tasks[st].name,
              result: stages[fs].tasks[task].sub_tasks[st].result,
              key: `sub_task-${stages[fs].tasks[task].sub_tasks[st].id}`
              });
            });
          });
        });

        return results;
      }
    },
    methods: {
      stageStyle(stage) {
        switch(stage.status) {
          case 'completed':
            return 'bg-brand-light';
          case 'in_progress':
            return 'bg-green-100';
          default:
            return 'bg-gray-200';
        }
      },
      stageIcon(stage) {
        switch(stage.status) {
          case 'complete':
            return '<span class="bg-blue-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="text-white h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>';
          case 'in_progress':
            return '<span class="bg-green-500 h-6 w-6 rounded-xl flex items-center justify-center"><svg class="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></span>';
          default:
            return '<span class="bg-gray-200 h-6 w-6 rounded-xl"></span>';
        }
      },
      updatedStatus(task) {
        switch (task.status) {
          case 'not_applicable':
            return 'incomplete';
          case 'in_progress':
            return 'completed';
          case 'completed':
            return 'not_applicable';
          default:
            return 'in_progress';
        }
      },
      getStageIndex(stageId) {
        return this.client_stages.findIndex(fs => fs.id === stageId);
      },
      toggleTask(stageId, taskIndex, attr, value) {
        let stages = [...this.client_stages];
        const stageIndex = this.getStageIndex(stageId);

        stages[stageIndex].tasks[taskIndex][attr] = value;

        if (attr === 'result') {
          stages[stageIndex].tasks[taskIndex].resultExpanded = false;
        }

        this.client_stages = stages;
      },
      updateTaskStatus(stageId, taskIndex) {
        let stages = [...this.client_stages];
        const stageIndex = this.getStageIndex(stageId);
        let task = stages[stageIndex].tasks[taskIndex];

        // If the task itself can be updated:
        if (task.sub_tasks.length === 0) {
          stages[stageIndex].tasks[taskIndex] = {
            ...stages[stageIndex].tasks[taskIndex],
            status: this.updatedStatus(stages[stageIndex].tasks[taskIndex])
          }
        }
        // } else if (task.sub_tasks.every(st => st.status === 'incomplete')) {
        //   // Toggle the tasks within
        //   Vue.set(stages[stageIndex].tasks[taskIndex], 'sub_tasks', task.sub_tasks.map(st => {
        //     st.status = 'not_applicable';

        //     return st;
        //   }));
        // } else if (task.sub_tasks.every(st => st.status === 'not_applicable')) {
        //   // Toggle the tasks within
        //   Vue.set(stages[stageIndex].tasks[taskIndex], 'sub_tasks', task.sub_tasks.map(st => {
        //     st.status = 'incomplete';

        //     return st;
        //   }));
        // }

        this.client_stages = stages;
        this.saveTask(stages[stageIndex].tasks[taskIndex]);
      },
      updateSubTaskStatus(stageId, taskIndex, subTaskIndex) {
        let stages = [...this.client_stages];
        const stageIndex = this.getStageIndex(stageId);
        const subTask = stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex];
        subTask.status = this.updatedStatus(subTask);

        this.client_stages = stages;

        this.saveSubTask(stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex]);

        //update the parent task status
        this.updateTaskStatus(stageId, taskIndex);
      },
      updateTask(stageId, taskIndex, attr, value) {
        let stages = [...this.client_stages];
        const stageIndex = this.getStageIndex(stageId);

        stages[stageIndex].tasks[taskIndex][attr] = value;

        if (attr === 'result') {
          stages[stageIndex].tasks[taskIndex].resultExpanded = false;
        }

        this.client_stages = stages;

        this.saveTask(stages[stageIndex].tasks[taskIndex]);
      },
      updateSubTask(stageId, taskIndex, subTaskIndex, attr, value) {
        let stages = [...this.client_stages];
        const stageIndex = this.getStageIndex(stageId);

        stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex][attr] = value;

        if (attr === 'result') {
          stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex].resultExpanded = false;
        }

        this.client_stages = stages;

        this.saveSubTask(stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex]);
      },
      addDocumentToTask(document, stageId, index, subTaskIndex) {
        const stageIndex = this.getStageIndex(stageId);

        if (subTaskIndex > -1) {
          if (!this.client_stages[stageIndex].tasks[index].sub_tasks[subTaskIndex].client_documents) {
            Vue.set(this.client_stages[stageIndex].tasks[index].sub_tasks[subTaskIndex], 'client_documents', []);
          }

          this.client_stages[stageIndex].tasks[index].sub_tasks[subTaskIndex].client_documents.push(
            {
              name: document.name,
              document: document
            }
          );

          this.saveSubTask(this.client_stages[stageIndex].tasks[index].sub_tasks[subTaskIndex]);
        } else {
          if (!this.client_stages[stageIndex].tasks[index].client_documents) {
            Vue.set(this.client_stages[stageIndex].tasks[index], 'client_documents', []);
          }

          this.client_stages[stageIndex].tasks[index].client_documents.push(
            {
              name: document.name,
              document: document
            }
          );
          this.saveTask(this.client_stages[stageIndex].tasks[index]);
        }
      },
      updateDocuments(stageId, index, subTaskIndex, documents) {
        const stageIndex = this.getStageIndex(stageId);

        if (subTaskIndex > -1) {
          Vue.set(this.client_stages[stageIndex].tasks[index].sub_tasks[subTaskIndex], 'client_documents', documents);
        } else {
          Vue.set(this.client_stages[stageIndex].tasks[index], 'client_documents', documents);
        }
      },
      loadOpportunity() {
        const url = `/staff/opportunities/${this.id}/client.json`;

        axios.get(url)
          .then(res => {
            let client_stages = [...res.data.client_stages];

            res.data.opp_client_tasks.forEach(oft => {
              client_stages.forEach((fs, stageIndex) => {
                const taskIndex = fs.tasks.findIndex(t => t.id === oft.client_task_id);

                if (taskIndex !== -1) {
                  client_stages[stageIndex].tasks[taskIndex] = {
                    ...client_stages[stageIndex].tasks[taskIndex],
                    ...oft,
                    id: client_stages[stageIndex].tasks[taskIndex].id,
                    opp_client_task_id: oft.id
                  }
                }
              });
            });

            res.data.opp_client_sub_tasks.forEach(oft => {
              client_stages.forEach((fs, stageIndex)=> {
                fs.tasks.forEach((t, taskIndex) => {
                  const subTaskIndex = t.sub_tasks.findIndex(st => st.id === oft.client_sub_task_id);

                  if (subTaskIndex !== -1) {
                    client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex] = {
                      ...client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex],
                      ...oft,
                      id: client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex].id,
                      opp_client_sub_task_id: oft.id
                    }
                  }
                });
              });
            });

            client_stages = client_stages.map(stage => {
              return {
                ...stage,
                toggled: res.data.active_stage === stage.id,
                showDescription: false,
                tasks: stage.tasks.map(task => {
                  let taskStatus = 'incomplete';

                  if (task.sub_tasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
                    taskStatus = 'completed';
                  } else if (task.sub_tasks.some(st => st.status === 'in_progress' || st.status === 'completed')) {
                    taskStatus = 'in_progress';
                  }

                  return {
                    ...task,
                    status: task.status ? task.status : 'incomplete',
                    toggled: false,
                    showDescription: false,
                    resultExpanded: false,
                    completedStatus: task.sub_tasks.length > 0 ? taskStatus : task.status,
                    sub_tasks: task.sub_tasks.map(subTask => {
                      return {
                        ...subTask,
                        status: subTask.status ? subTask.status : 'incomplete',
                        resultExpanded: false
                      }
                    })
                  }
                })
              }
            });

            client_stages = client_stages.map(stage => {
              let status = 'incomplete';

              if (stage.tasks.every(t => t.completedStatus === 'completed')) {
                status = 'completed';
              } else if (stage.tasks.some(t => t.completedStatus === 'in_progress' || t.completedStatus === 'completed')) {
                status = 'in_progress';
              }

              return { ...stage, status };
            });

            this.client_stages = client_stages;

            this.workflow_stage = res.data.workflow_stage;
            this.workflow_stage_id = res.data.workflow_stage_id;
            this.lead_source_id = res.data.lead_source_id;
            this.lead_status_id = res.data.lead_status_id;
            this.confidence_score_id = res.data.confidence_score_id;
            this.user_id = res.data.user_id;
            this.users = res.data.users;
            this.opp_type = res.data.opp_type;
            this.lead_statuses = res.data.lead_statuses;
            this.lead_sources = res.data.lead_sources;
            this.confidence_scores = res.data.confidence_scores;
            this.workflow_stages = res.data.workflow_stages;

            window.setTimeout(() => {
              this.pageLoaded = true;
            }, 500);
          });
      },
      saveWithThrottle: function() { this.saveOpportunity() },
      saveTask: function(task) {
        let url = `/staff/opp_client_tasks.json`;
        let method = 'post';
        let formData = new FormData();
        let params = {};

        if (task.opp_client_task_id) {
          url = `/staff/opp_client_tasks/${task.opp_client_task_id}.json`;
          method = 'patch';
          params = { ...task, result: task.result || '' };
        } else {
          params = {
            status: task.status,
            client_task_id: task.id,
            opportunity_id: this.id,
            client_documents: task.client_documents || [],
            result: '',
          };
        }

        Object.entries(params).forEach(([key, value]) => {
          if (key == 'client_documents') {
            value.filter(doc => !doc.id).forEach(document => {
              formData.append(`opp_client_task[client_documents_attributes][][name]`, document.name);
              formData.append(`opp_client_task[client_documents_attributes][][document]`, document.document);
            });
          } else {
            formData.append(`opp_client_task[${key}]`, value);
          }
        });

        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf, 'Content-Type': 'multipart/form-data' };

        axios({ method, url, data: formData, headers }).then(({ data }) => {
          let stageIndex = -1;
          let taskIndex = -1;

          this.client_stages.some((fs, si) => {
            taskIndex = fs.tasks.findIndex(t => t.id === data.client_task_id);

            if (taskIndex !== -1) {
              stageIndex = si;

              return true;
            }
          });

          if (taskIndex === -1) return;

          const newTask = {
            ...this.client_stages[stageIndex].tasks[taskIndex],
            completed_by: data.completed_by,
            actioned_by: data.actioned_by,
            completed_at: data.completed_at,
            opp_client_task_id: data.id,
            client_documents: data.client_documents
          }

          this.$set(this.client_stages[stageIndex].tasks, taskIndex, newTask);

          let stageStatus = 'incomplete';

          if (this.client_stages[stageIndex].tasks.every(t => t.status === 'completed' || t.status === 'not_applicable')) {
            stageStatus = 'completed';
          } else if (this.client_stages[stageIndex].tasks.some(t => t.status === 'in_progress')) {
            stageStatus = 'in_progress';
          }
          this.$set(this.client_stages[stageIndex], 'status', stageStatus);
        });
      },
      saveSubTask: function(sub_task) {
        let url = `/staff/opp_client_sub_tasks.json`;
        let method = 'post';
        let formData = new FormData();
        let params = {};

        if (sub_task.opp_client_sub_task_id) {
          url = `/staff/opp_client_sub_tasks/${sub_task.opp_client_sub_task_id}.json`;
          method = 'patch';
          params = { ...sub_task, result: sub_task.result || '' };
        } else {
          params = {
            status: sub_task.status,
            opportunity_id: this.id,
            client_sub_task_id: sub_task.id,
            client_documents: sub_task.client_documents || []
          }
        }

        Object.entries(params).forEach(([key, value]) => {
          if (key == 'client_documents') {
            value.filter(doc => !doc.id).forEach(document => {
              formData.append(`opp_client_sub_task[client_documents_attributes][][name]`, document.name);
              formData.append(`opp_client_sub_task[client_documents_attributes][][document]`, document.document);
            });
          } else {
            formData.append(`opp_client_sub_task[${key}]`, value);
          }
        });

        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };

        axios({ method, url, data: formData, headers }).then(({ data }) => {
          let stageIndex = -1;
          let taskIndex = -1;
          let subTaskIndex = -1;

          this.client_stages.some((fs, si) => {
            return fs.tasks.some((task, ti) => {
              subTaskIndex = task.sub_tasks.findIndex(st => st.id === data.id);

              if (subTaskIndex !== -1) {
                stageIndex = si;
                taskIndex = ti;

                return true;
              }
            })
          });

          if (subTaskIndex === -1) return;

          this.$set(this.client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'completed_by', data.completed_by);
          this.$set(this.client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'actioned_by', data.actioned_by);
          this.$set(this.client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'completed_at', data.completed_at);
          this.$set(this.client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'opp_client_sub_task_id', data.opp_client_sub_task_id);
          this.$set(this.client_stages[stageIndex].tasks[taskIndex].sub_tasks[subTaskIndex], 'client_documents', data.client_documents);

          if (this.client_stages[stageIndex].tasks[taskIndex].sub_tasks.every(st => st.status === 'completed' || st.status === 'not_applicable')) {
            this.$set(this.client_stages[stageIndex].tasks[taskIndex], 'status', 'completed');
          } else if (this.client_stages[stageIndex].tasks[taskIndex].sub_tasks.some(st => st.status === 'in_progress')) {
            this.$set(this.client_stages[stageIndex].tasks[taskIndex], 'status', 'in_progress');
          } else {
            this.$set(this.client_stages[stageIndex].tasks[taskIndex], 'status', 'incomplete');
          }

          let stageStatus = 'incomplete';
          if (this.client_stages[stageIndex].tasks.every(t => t.status === 'completed' || t.status === 'not_applicable')) {
            stageStatus = 'completed';
          } else if (this.client_stages[stageIndex].tasks.some(t => t.status === 'in_progress')) {
            stageStatus = 'in_progress';
          }
          this.$set(this.client_stages[stageIndex], 'status', stageStatus);
        });
      },
      saveOpportunity() {
        const url = `/staff/opportunities/${this.id}.json`;
        const csrf = document.getElementsByName('csrf-token')[0].content;
        const headers = { 'x-csrf-token': csrf };
        const activeStage = this.client_stages.find(stage => {
          return stage.tasks.some(t => t.status === 'in_progress' || t.status === 'incomplete');
        });
        const activeStageId = activeStage ? activeStage.id : null;
        const data = {
          opportunity: {
            lead_source_id: this.lead_source_id,
            lead_status_id: this.lead_status_id,
            confidence_score_id: this.confidence_score_id,
            opp_type: this.opp_type,
            user_id: this.user_id,
            active_stage: activeStageId
          }
        };

        axios.patch(url, data, { headers });
      }
    }
  }
</script>
